/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces */
<template>
  <div>

    <!-- Media -->
    <b-row>
      <b-col md="4">
        <b-media class="mb-2">
          <template #aside>
            <b-avatar
              ref="previewEl"
              :src="userData.avatar_url"
              :text="avatarText(userData.name)"
              size="90px"
              rounded
            />
          </template>
          <h4 class="mb-1">
            {{ userData.name }}
          </h4>
          <div class="d-flex flex-wrap">
            <b-button
              variant="primary"
              @click="toggleShow"
            > <span class="d-none d-sm-inline">{{ $t('Update') }}</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
            <my-upload
              v-model="profileImageParams.show"
              field="avatar"
              :width="300"
              :height="300"
              :url="postUrl"
              lang-type="en"
              :params="profileImageParams.params"
              :headers="profileImageParams.headers"
              img-format="png"
              :with-credentials="false"
              @crop-success="cropSuccess"
              @crop-upload-success="cropUploadSuccess"
              @crop-upload-fail="cropUploadFail"
            />
            <b-button
              v-if="userData.profile_image!=''"
              variant="outline-secondary"
              class="ml-1"
              @click="removeProfileImage()"
            >
              <span class="d-none d-sm-inline">{{ $t('Remove') }}</span>
              <feather-icon
                icon="TrashIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
          </div>
        </b-media>
      </b-col>
      <b-col md="4">
        <!-- İmza -->
        <b-media class="mb-2">
          <template #aside>
            <b-img
              :src="userData.signature_url"
              :blank="!userData.signature_url"
              fluid
              rounded
              blank-color="#5A8DEE"
              alt="Fluid image"
              width="150px"
              height="75px"
            />
          </template>
          <h4 class="mb-1">
            {{ this.$t('Signature') }}
          </h4>
          <div class="d-flex flex-wrap">
            <b-button
              variant="primary"
              @click="toggleShowSignature"
            >
              <span class="d-none d-sm-inline">{{ $t('Update') }}</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
            <my-upload
              v-model="signatureImageParams.show"
              field="signature_image"
              :width="300"
              :height="150"
              :url="postUrlSignature"
              lang-type="en"
              :params="signatureImageParams.params"
              :headers="signatureImageParams.headers"
              img-format="png"
              :with-credentials="false"
              @crop-success="cropSuccessSignature"
              @crop-upload-success="cropUploadSuccessSignature"
              @crop-upload-fail="cropUploadFailSignature"
            />
            <b-button
              v-if="userData.signature_image!=''"
              variant="outline-secondary"
              class="ml-1"
              @click="removeSignatureImage()"
            >
              <span class="d-none d-sm-inline">{{ $t('Remove') }}</span>
              <feather-icon
                icon="TrashIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
          </div>
        </b-media>
      </b-col>
      <b-col md="4">
        <validation-observer ref="formPassword">
          <b-form autocomplete="chrome-off">
            <b-row>
              <validation-provider
                #default="{ errors }"
                name="newpassword"
                rules="required|min:9"
              >
                <b-form-group
                  :label="$t('New Password')"
                  label-for="new_password_KDR1"
                >
                  <b-input-group>

                    <b-form-input
                      id="new_password_KDR"
                      v-model="newPassword"

                      autocomplete="chrome-off"
                      :state="errors.length > 0 ? false:null"
                      placeholder="*********"
                      type="password"
                    />

                    <b-input-group-append>
                      <b-button
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        variant="outline-primary"
                        @click="changePassword"
                      >
                        {{ $t('Change Password') }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-row></b-form>

        </validation-observer></b-col>
    </b-row>
    <!-- User Info: Input Fields -->
    <validation-observer ref="formRules">
      <b-form>
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="5"
          >

            <b-form-group
              :label="$t('Ad')"
              label-for="name_KDR"
            > <validation-provider
              #default="{ errors }"
              name="Ad"
              rules="required|min:3"
            >
              <b-form-input
                id="name_KDR"
                v-model="userData.name"
                autocomplete="chrome-off"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Soyad')"
              label-for="surname_KDR"
            >
              <b-form-input
                id="surname_KDR"
                v-model="userData.surname"
                autocomplete="chrome-off"
              />
            </b-form-group>
            <b-form-group
              label="Email"
              label-for="email_KDR"
            ><validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="email_KDR"
                v-model="userData.email"
                readonly="on"
                autocomplete="chrome-off"
                :state="errors.length > 0 ? false:null"
                type="email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
            <b-form-group
              label="Uyum Kod"
              label-for="uyum_code_KDR"
            >
              <b-form-input
                id="uyum_code_KDR"
                v-model="userData.uyum_code"
                readonly="on"
                autocomplete="chrome-off"
              />
            </b-form-group>
            <b-form-group
              v-if="countryOptions"
              :label="$t('Ülke')"
              label-for="country_KDR"
            >
              <v-select
                v-model="userData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :reduce="val => val.value"
                autocomplete="chrome-off"
                :clearable="false"
                input-id="country_KDR"
              />
            </b-form-group>
            <b-form-group
              :label="$t('Varsayılan Teklif Dili')"
              label-for="lang_KDR"
            >

              <v-select
                id="lang_KDR"
                v-model="userData.lang"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$staticParams.locales"
                :reduce="val => val.locale"
                :clearable="false"
                input-id="lang_KDR"
              >
                <template #option="{ name,img,locale }">
                  <b-img
                    :src="img"
                    height="14px"
                    width="22px"
                    :alt="locale"
                  />

                  <span> {{ name }}</span>
                </template>
                <template #selected-option="{ name,img,locale }">
                  <b-img
                    :src="img"
                    height="14px"
                    width="22px"
                    :alt="locale"
                  />

                  <span>  {{ name }}</span>
                </template>
              </v-select>

            </b-form-group>
            <b-form-group
              :label="$t('E-Mail Şifresi (Toplu Mail İçin)')"
              label-for="emal_password_KDR"
            >
              <b-form-input
                id="email_password_KDR"
                v-model="userData.email_password"
                autocomplete="chrome-off"
              />
            </b-form-group>
            <b-form-group
              :label="$t('İmza Kaşe')"
              label-for="signature"
            >
              <quill-editor
                id="signature"
                v-model="userData.signature"
                :options="snowOption"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveUser"
        >
          {{ $t('Kaydet') }}
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BInputGroup, BInputGroupAppend, BImg, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line no-unused-vars
import myUpload from 'vue-image-crop-upload/upload-2.vue'
import {
  required, alphaNum, email,
} from '@validations'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    quillEditor,
    myUpload,
    BImg,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      newPassword: '',
    }
  },
  methods: {
    successMsg() {
      this.$bvToast.toast(i18n.t('Successful'), {
        title: i18n.t('Success'),
        toaster: 'b-toaster-bottom-center',
        variant: 'success',
        solid: true,
      })
    },
    changePassword() {
      this.$refs.formPassword.validate().then(success => {
        if (success) {
          store.dispatch('app-my-settings/changePassword', { password: this.newPassword, password_confirmation: this.newPassword, user_id: this.userData.id })
            .then(() => {
              this.successMsg()
            })
            .error(() => {
              this.newPassword = ''
              this.$bvToast.toast(i18n.t('Şifre değiştirme işlemi başarısız. 9-30 karakterden oluşan bir şifre girerek tekrar deneyiniz.'), {
                title: i18n.t('Başarısız'),
                toaster: 'b-toaster-bottom-center',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
    saveUser() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          store.dispatch('app-my-settings/updateUser', this.userData)
            .then(() => {
              this.userData.profile_image = ''
              this.userData.avatar_url = ''
              this.successMsg()
            })
        }
      })
    },
    removeProfileImage() {
      this.$swal({
        title: i18n.t('Are you sure?'),
        text: i18n.t('Are you sure you want to delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('Yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-my-settings/removeProfileImage', { user_id: this.userData.id })
            .then(() => {
              this.userData.profile_image = ''
              this.userData.avatar_url = ''
              this.successMsg()
            })
        }
      })
    },
    removeSignatureImage() {
      this.$swal({
        title: i18n.t('Are you sure?'),
        text: i18n.t('Are you sure you want to delete?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('Yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-my-settings/removeSignatureImage', { user_id: this.userData.id })
            .then(() => {
              this.userData.signature_url = ''
              this.userData.signature_image = ''
              this.successMsg()
            })
        }
      })
    },
    toggleShow() {
      this.profileImageParams.show = !this.profileImageParams.show
    },
    // eslint-disable-next-line no-unused-vars
    cropSuccess(imgDataUrl, field) {
      this.profileImageParams.imgDataUrl = imgDataUrl
    },
    // eslint-disable-next-line no-unused-vars
    cropUploadSuccess(jsonData, field) {
      this.userData.avatar_url = jsonData.data.avatar
    },
    // eslint-disable-next-line no-unused-vars
    cropUploadFail(status, field) {
      this.$bvToast.toast(i18n.t('Upload failed. Make sure you send the file in the correct format and try again.'), {
        title: i18n.t('Fail'),
        toaster: 'b-toaster-bottom-center',
        variand: 'danger',
        solid: true,
      })
    },
    toggleShowSignature() {
      this.signatureImageParams.show = !this.signatureImageParams.show
    },
    // eslint-disable-next-line no-unused-vars
    cropSuccessSignature(imgDataUrl, field) {
    },
    // eslint-disable-next-line no-unused-vars
    cropUploadSuccessSignature(jsonData, field) {
    },
    // eslint-disable-next-line no-unused-vars
    cropUploadFailSignature(status, field) {
      this.$bvToast.toast(i18n.t('Upload failed. Make sure you send the file in the correct format and try again.'), {
        title: i18n.t('Fail'),
        toaster: 'b-toaster-bottom-center',
        variand: 'danger',
        solid: true,
      })
    },
  },
  setup(props) {
    const postUrl = `${process.env.VUE_APP_API_URL}/api/my-settings/updateavatar`
    const postUrlSignature = `${process.env.VUE_APP_API_URL}/api/my-settings/updatesignature`

    const countryOptions = ref(null)

    store.dispatch('app-my-settings/fetchCountries', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ label: val.name, value: val.code }))
        countryOptions.value = arr
      })

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const roleTableColumns = [
      { key: 'name', label: i18n.t('Rol') },
      { key: 'selected', label: i18n.t('') },
    ]
    const usersTableColumns = [
      { key: 'name', label: i18n.t('Kullanıcı') },
      { key: 'selected_proposal', label: i18n.t('Teklif') },
      { key: 'selected_email', label: i18n.t('Toplu Mail') }]

    // profile image upload params
    const profileImageParams = ref({
      show: false,
      params: {
        token: '123456798',
        user_id: props.userData.id,
        name: '',
      },
      headers: {
        Authorization: useJwt.getTokenWİthTokenType(),
      },
      imgDataUrl: '', // the datebase64 url of created image
    })
    // signature image upload params
    const signatureImageParams = ref({
      show: false,
      params: {
        token: '123456798',
        user_id: props.userData.id,
        name: '',
      },
      headers: {
        Authorization: useJwt.getTokenWİthTokenType(),
      },
      imgDataUrl: '', // the datebase64 url of created image
    })

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      avatarText,
      statusOptions,
      countryOptions,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      roleTableColumns,
      usersTableColumns,
      snowOption: {
        theme: 'snow',
      },
      profileImageParams,
      postUrl,
      signatureImageParams,
      postUrlSignature,
    }
  },
}
</script>
