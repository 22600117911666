import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/my-settings')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/countries', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/roles', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/my-settings', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    removeProfileImage(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/my-settings/removeprofileimage', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/my-settings/changepassword', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeSignatureImage(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/my-settings/removesignature', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
